<script>
import { useForm, useModelValue, useValidationProvider } from "@/core-ui";
import PickupTimeSelect from "./PickupTimeSelect/PickupTimeSelect.vue";

export default {
  name: "CheckinRequestPickupTimeSelect",
  components: { PickupTimeSelect },
  mixins: [useForm(), useModelValue(), useValidationProvider()],
  inheritAttrs: false,
  methods: {
    validator() {
      return this.$refs.provider;
    },
  },
};
</script>

<template>
  <ValidationProvider
    ref="provider"
    :vid="name"
    :name="label"
    :rules="rules"
    :mode="mode"
    slim
  >
    <template #default="{ errors }">
      <PickupTimeSelect
        v-model="modelValue"
        :required="rules.includes('required')"
        :error-messages="errors"
        :name="name"
        v-bind="$attrs"
        v-on="$listeners"
      >
        <template
          v-for="(value, name) in $scopedSlots"
          v-slot:[name]="slotProps"
        >
          <slot :name="name" v-bind="slotProps"></slot>
        </template>
      </PickupTimeSelect>
    </template>
  </ValidationProvider>
</template>
