//import CheckinRequestDetailViewV1 from "./CheckinRequestDetailViewV1.vue";
//import CheckinRequestDetailView from "./CheckinRequestDetailView.vue";
import { defineFeatureComponent } from "@/core-ui";

export default defineFeatureComponent({
  name: "CheckinRequestDetailView",
  features: [
    {
      feature: "checkinFlowVersion",
      variant: "V1",
      component: () =>
        import(
          /* webpackChunkName: "buys_v1" */ "./CheckinRequestDetailViewV1.vue"
        ),
      //component:  CheckinRequestDetailViewV1,
    },
    {
      feature: "checkinFlowVersion",
      variant: "V2",
      component: () =>
        import(
          /* webpackChunkName: "buys_v2" */ "./CheckinRequestDetailView.vue"
        ),
      //component: CheckinRequestDetailView,
    },
  ],
});
