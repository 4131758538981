<script>
import { upperFirst, debounce } from "lodash";
import { VxTextField } from "@/core-ui";
//import { VxBtn } from "@/core-ui";
import CheckinRequestContainerInput from "./CheckinRequestContainerInput";
import CheckinRequestPickupTimeSelect from "./CheckinRequestPickupTimeSelect";

export default {
  name: "CheckinRequestMetaForm",
  components: {
    //VxBtn,
    VxTextField,
    CheckinRequestContainerInput,
    CheckinRequestPickupTimeSelect,
  },
  inheritAttrs: false,
  props: {
    canInputFormData: {
      type: Boolean,
      default: true,
    },
    containerNum: {
      type: Number,
      default: 0,
    },
    containerDescription: {
      type: String,
      default: "",
    },
    signatureFileName: {
      type: String,
      default: "",
    },
    signatureUrl: {
      type: String,
      default: "",
    },
    estimatedPickupAt: {
      type: String,
      default: "",
    },
    status: {
      type: String,
      default: "",
    },
    storeId: { type: [Number, String], default: undefined },
    showSignatureField: { type: Boolean, default: true },
  },
  data: (vm) => ({
    localContainerNum: vm.containerNum || 0,
    localContainerDescription: vm.containerDescription,
    localEstimatedPickupAt: vm.estimatedPickupAt,
    localSignatureFileName: vm.signatureFileName,
    loading: {
      containerNum: false,
      containerDescription: false,
      estimatedPickupAt: false,
    },
  }),
  watch: {
    containerNum(v) {
      this.localContainerNum = v || 0;
    },
    containerDescription(v) {
      this.localContainerDescription = v;
    },
    estimatedPickupAt(v) {
      this.localEstimatedPickupAt = v;
    },
    signatureFileName(v) {
      this.localSignatureFileName = v;
    },
    loading: {
      handler(v) {
        this.$emit(
          "loading",
          Object.values(v).some((e) => e)
        );
      },
      deep: true,
    },
  },
  methods: {
    // Public

    // Transform a prop name into a local data name
    // e.g., containerNum -> localContainerNum
    localDataName(field) {
      return `local${upperFirst(field)}`;
    },
    // update the buy
    handleUpdate(field) {
      // Toggle loading for the field
      this.loading[field] = true;
      this.handleUpdateDebunced(field);
    },
    handleUpdateDebunced: debounce(async function (field) {
      this.$emit(
        "update:field",
        field,
        this[this.localDataName(field)],
        "buy",
        () => {
          this.loading[field] = false;
        }
      );
    }, 300),
    setContainersNum(containerNum) {
      this.localContainerNum = containerNum;
      this.handleUpdate("containerNum");
    },
  },
};
</script>

<template>
  <div>
    <span class="d-flex align-center text-subtitle-2">
      <v-icon class="mr-2">$shoppingBag</v-icon>
      Buy details
    </span>

    <v-row class="mt-2 mx-5">
      <v-col cols="12" md="6">
        <v-row class="align-center">
          <v-col cols="12" class="py-0">
            <div class="d-flex align-center">
              <h5 class="text-h6">containers</h5>

              <CheckinRequestContainerInput
                ref="localContainerNum"
                v-model="localContainerNum"
                :disabled="!canInputFormData"
                :loading="loading.containerNum"
                name="containerNum"
                rules="required|min_value:1"
                @input="() => handleUpdate('containerNum')"
              />
            </div>
          </v-col>
        </v-row>
        <v-row class="align-center mt-4 mb-4">
          <v-col cols="12" class="py-0">
            <!-- <div class="d-flex">
              <VxBtn
                :disabled="!canInputFormData"
                color="secondary"
                class="mr-3"
                @click="() => setContainersNum(1)"
              >
                1
              </VxBtn>
              <VxBtn
                :disabled="!canInputFormData"
                color="secondary"
                class="mr-3"
                @click="() => setContainersNum(2)"
              >
                2
              </VxBtn>
              <VxBtn
                :disabled="!canInputFormData"
                color="secondary"
                class="mr-3"
                @click="() => setContainersNum(3)"
              >
                3
              </VxBtn>
            </div> -->
          </v-col>
        </v-row>

        <v-row>
          <v-col class="py-0">
            <VxTextField
              ref="localContainerDescription"
              v-model="localContainerDescription"
              :disabled="!canInputFormData"
              dense
              label="Description"
              :loading="loading.containerDescription"
              name="containerDescription"
              rules="required|min:3"
              @input="handleUpdate('containerDescription')"
            />
          </v-col>
        </v-row>

        <v-row v-if="!signatureUrl && showSignatureField">
          <v-col>
            <h5 class="text-h6 mr-6 time-signature-title-column">Signature</h5>
            <div
              v-if="!signatureFileName"
              class="font-italic text-subtitle-2 mt-1"
              v-text="`Waiting on customer signature`"
            />
          </v-col>
        </v-row>

        <v-row v-if="signatureUrl && showSignatureField">
          <v-col>
            <h5 class="text-h6 mr-6 time-signature-title-column">Signature</h5>
            <img
              class="py-2"
              :src="signatureUrl"
              :alt="'Customer signature'"
              style="max-height: 95px"
            />
          </v-col>
        </v-row>
      </v-col>
      <v-col>
        <v-row>
          <v-col>
            <!-- pickup time -->
            <CheckinRequestPickupTimeSelect
              ref="localEstimatedPickupAt"
              v-model="localEstimatedPickupAt"
              :disabled="!canInputFormData"
              :store-id="storeId"
              :loading="loading.estimatedPickupAt"
              name="estimatedPickupAt"
              rules="required"
              @input="handleUpdate('estimatedPickupAt')"
            />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<style scoped>
.form-column {
  flex-basis: 50%;
}

.time-signature-title-column {
  flex-basis: 130px;
}
</style>
