<template>
  <v-list-item
    :class="['drs-customer-list-item', classes]"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <v-list-item-content>
      <v-list-item-title
        :class="`${
          !isComparisonEmpty && fullNameConflict ? 'warning--text' : ''
        }`"
      >
        {{ fullName }}
      </v-list-item-title>
      <v-list-item-subtitle>
        <span
          :class="`${
            !isComparisonEmpty && phoneNumberConflict ? 'warning--text' : ''
          }`"
          >{{ phoneNumber }}</span
        >
        &mdash; {{ customer.numBuys }} buys
      </v-list-item-subtitle>
      <v-list-item-subtitle v-if="updatedAt" class="text-caption">
        Last updated {{ updatedAt }}
      </v-list-item-subtitle>
    </v-list-item-content>
    <v-list-item-action>
      <v-tooltip v-if="customer.flagged" bottom>
        <template #activator="{ on, attrs }">
          <v-icon
            color="error"
            :x-small="$attrs.dense"
            :small="!$attrs.dense"
            v-bind="attrs"
            v-on="on"
          >
            $flag
          </v-icon>
        </template>
        <span>{{ customer.flagReason }}</span>
      </v-tooltip>
      <v-spacer />
      <v-list-item-action-text>
        {{ drsCustomerCode }}
      </v-list-item-action-text>
    </v-list-item-action>
  </v-list-item>
</template>

<script>
import { computed } from "vue";
import { relativeTime } from "@/utils/datetime";
import { formatPhoneNumber, cleanPhoneNumber } from "@/core-ui";
import { isEqual } from "lodash";

export default {
  name: "DrsCustomerListItem",
  props: {
    drsCustomer: {
      type: Object,
      default: () => ({}),
    },
    compareDrsCustomer: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props) {
    const customer = computed(() => props.drsCustomer?.customer ?? {});

    const classes = computed(() => ({
      "error--text": customer.value?.flagged,
    }));

    const phoneNumber = computed(() => {
      return customer.value?.phoneNumber
        ? formatPhoneNumber(customer.value?.phoneNumber)
        : null;
    });

    const updatedAt = computed(() => {
      return customer.value?.updatedAt
        ? relativeTime(customer.value.updatedAt)
        : null;
    });

    const isComparisonEmpty = computed(() => {
      return Object.values(props.compareDrsCustomer || {}).every(
        (value) => value === null || value === "" || value === undefined
      );
    });

    const fullNameConflict = computed(() => {
      return !isEqual(
        props.drsCustomer?.fullName?.toUpperCase(),
        props.compareDrsCustomer?.fullName?.toUpperCase()
      );
    });

    const fullName = computed(() => {
      return props.drsCustomer?.fullName;
    });

    const drsCustomerCode = computed(() => {
      return props.drsCustomer?.code;
    });

    const phoneNumberConflict = computed(() => {
      return !isEqual(
        cleanPhoneNumber(phoneNumber.value),
        cleanPhoneNumber(props.compareDrsCustomer?.phoneNumber)
      );
    });

    return {
      customer,
      classes,
      phoneNumber,
      updatedAt,
      isComparisonEmpty,
      fullNameConflict,
      phoneNumberConflict,
      fullName,
      drsCustomerCode,
    };
  },
};
</script>

<style lang="scss">
.drs-customer-list-item:not(.v-list-item--dense) .v-list-item__subtitle {
  font-size: 0.875rem;
}

.drs-customer-list-item.error--text .v-list-item__subtitle {
  color: unset !important;
}
</style>
