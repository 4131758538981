export const hasVModel = {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    localValue: {
      get() {
        return this.value;
      },
      set(v) {
        return this.$emit("input", v);
      },
    },
  },
};
