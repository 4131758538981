<template>
  <VxSelect
    ref="select"
    v-click-outside="{
      handler: onClickOutsideSelect,
      include: onClickOutsideSelectInclude,
    }"
    single-line
    read-only
    :class="selectClasses"
    :menu-props="menuProps"
    :items="items"
    :item-text="itemText"
    :item-value="getItemValue"
    :value-comparator="compareValues"
    outlined
    :label="$t('buy.app.drsCustomerSelect.selectLabel')"
    :placeholder="$t('buy.app.drsCustomerSelect.selectLabel')"
    return-object
    v-bind="$attrs"
    :value="value"
    v-on="$listeners"
    @focus="onFocusSelect"
    @click="onFocusSelect"
  >
    <template #prepend>
      <VxTextField
        ref="searchField"
        v-model="internalSearchTerm"
        class="search-text-field"
        dense
        hide-details
        outlined
        :label="$t('buy.app.drsCustomerSelect.searchLabel')"
        :placeholder="$t('buy.app.drsCustomerSelect.searchPlaceholder')"
        @input="onInternalInputChange"
        @focus="onFocusSearchField"
        @blur="onBlurSelect"
      />
    </template>
    <template #selection="{ item }">
      <DrsCustomerListItem
        class="pl-0"
        :drs-customer="item"
        :compare-drs-customer="compareDrsCustomer"
        dense
      />
    </template>
    <template #item="{ item }">
      <DrsCustomerListItem
        class="mx-n4"
        :drs-customer="item"
        :compare-drs-customer="compareDrsCustomer"
        dense
        @click="onCustomerSelect(item)"
      />
    </template>
    <template #append>
      <v-icon v-if="value" @click="onClearSelect">$clear</v-icon>
      <v-icon>$dropdown</v-icon>
    </template>
    <template v-if="!items.length" #no-data>
      <v-list-item>
        <v-list-item-title class="text-center text-caption">
          {{ $tc("buy.app.drsCustomerSelect.count", items.length) }}
        </v-list-item-title>
      </v-list-item>
    </template>
    <template #append-item>
      <div class="drs-customer-select__append-item">
        <v-divider class="mb-1" />
        <v-list-item v-if="items.length">
          <v-list-item-title class="text-center text-caption">
            {{ $tc("buy.app.drsCustomerSelect.count", items.length) }}
          </v-list-item-title>
        </v-list-item>
        <v-list-item @click="onCreateCustomer">
          <v-list-item-content>
            <v-list-item-title>
              Create new DRS customer:
              <span class="font-weight-bold">{{ internalSearchTerm }}</span>
            </v-list-item-title>
          </v-list-item-content>
          <v-list-item-icon>
            <v-icon>$assignTask</v-icon>
          </v-list-item-icon>
        </v-list-item>
      </div>
    </template>
  </VxSelect>
</template>

<script>
import { VxSelect, VxTextField } from "@/core-ui";
import DrsCustomerListItem from "./DrsCustomerListItem.vue";

export default {
  name: "DrsCustomerSelect",
  components: { VxSelect, VxTextField, DrsCustomerListItem },
  props: {
    compareDrsCustomer: {
      type: Object,
      default: () => null,
    },
    items: {
      type: Array,
      default: () => [],
    },
    itemValue: {
      type: String,
      default: "id",
    },
    itemText: {
      type: String,
      default: "fullName",
    },
    menuProps: {
      type: Object,
      default: () => ({
        transition: "slide-y-transition",
        nudgeBottom: 5,
        rounded: "lg",
        "offset-y": true,
      }),
    },
    searchTerm: {
      type: String,
      default: null,
    },
    value: {
      type: Object,
      default: () => null,
    },
  },
  data: (vm) => ({
    query: null,
    internalSearchTerm: vm.searchTerm,
    isFocused: false,
  }),
  computed: {
    selectClasses() {
      return {
        "drs-customer-select": true,
        "flex-column": true,
        "align-stretch": true,
        "v-input--is-focused primary--text": this.isFocused,
      };
    },
  },
  watch: {
    searchTerm(v) {
      this.internalSearchTerm = v;
      this.$refs.select.openMenu();
    },
  },
  methods: {
    onCustomerSelect(item) {
      this.internalSearchTerm = null;
      this.$emit("select", item);
      this.$refs.select.closeMenu();
    },
    onClearSelect(event) {
      event.stopImmediatePropagation();
      event.stopPropagation();
      this.$emit("select", null);

      this.$refs.searchField.focus();
      this.$refs.select.closeMenu();
    },
    onInternalInputChange(v) {
      this.$emit("updateSearchTerm", v);
    },
    onCreateCustomer(v) {
      this.$emit("create", this.internalSearchTerm);
      this.$refs.select.closeMenu();
    },
    onFocusSelect() {
      // Need to do it in timeout, because the select will again get focus after menu is opened
      // - so nextTick would not work, as with next tick search field get focus, and the select is refocused
      setTimeout(() => {
        this.$refs.searchField.focus();
      }, 100);
    },
    onFocusSearchField() {
      this.isFocused = true;
      this.$refs.select.openMenu();
    },
    onBlurSelect() {
      this.isFocused = false;
    },
    onClickOutsideSelect() {
      this.$refs.select.closeMenu();
    },
    onClickOutsideSelectInclude() {
      // took the code from vuetify to get all opened stuff from select
      const result = [];
      const openDependents = this.searchChildren(this.$children);
      for (let index = 0; index < openDependents.length; index++) {
        if (openDependents[index].getClickableDependentElements) {
          result.push(...openDependents[index].getClickableDependentElements());
        }
      }
      return result;
    },
    searchChildren(children) {
      // took the code from vuetify to get all opened stuff from select
      const results = [];
      for (let index = 0; index < children.length; index++) {
        const child = children[index];
        if (child.isActive && child.isDependent) {
          results.push(child);
        } else {
          results.push(...this.searchChildren(child.$children));
        }
      }

      return results;
    },
    // TODO - try to clear this with getItem value
    // should be possible to remove it
    getItemValue(item) {
      return item;
    },
    compareValues(item1, item2) {
      if (!item1 || !item2) {
        return false;
      }

      return item1.id === item2.id;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .search-text-field .v-input__slot {
  margin-bottom: -1px;
}
::v-deep .search-text-field .v-input__slot fieldset {
  border-bottom: none;
}
::v-deep .v-input__append-inner {
  align-self: center;
  margin-top: 0px;
}
::v-deep .drs-customer-select__append-item {
  position: sticky;
  background-color: inherit;
  padding-bottom: 4px;
  bottom: 0;
}

::v-deep .v-input__prepend-outer {
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 0;
}
</style>
