<template>
  <EmployeeAction v-slot="{ handleAction }">
    <v-btn
      text
      class="checkinRequest-dialog__action-button--checkin elevation-0"
      :color="color"
      v-bind="$attrs"
      :disabled="disabled"
      :loading="loading"
      @click="handleAction(handleClick)"
      v-text="`Check in`"
    />
  </EmployeeAction>
</template>

<script>
import gql from "graphql-tag";
import {
  injectActiveEmployee,
  hasActiveEmployeeIdAccess,
} from "@/mixins/employee";
import { hasSnackbarAccess } from "@/mixins/ui";
import { buildCheckinPayload } from "@/utils/buy";

import { format } from "date-fns";
import { CREATE_BUY_FROM_CHECKIN_REQUEST } from "../graphql";
import EmployeeAction from "@/components/employees/EmployeeAction.js";

const mutation = gql`
  mutation ACTION_BUTTON_CHECKIN_BUY($input: UpdateBuyInputObject!) {
    updateBuy(input: $input) {
      buy {
        id
        checkedInByEmployeeId
        checkedInAt
        status
      }
      errors
    }
  }
`;

export default {
  components: { EmployeeAction },
  mixins: [injectActiveEmployee, hasSnackbarAccess, hasActiveEmployeeIdAccess],
  inheritAttrs: false,
  inject: ["hideDialog"],
  props: {
    checkinRequest: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
    },
    color: {
      type: String,
      default: "accent",
    },
  },
  data: () => ({
    loading: false,
  }),
  methods: {
    async createBuyFromCheckinRequest(checkinRequestId) {
      const variables = {
        input: {
          checkinRequestId: checkinRequestId,
        },
      };

      return this.$apollo
        .mutate({
          mutation: CREATE_BUY_FROM_CHECKIN_REQUEST,
          variables,
        })
        .catch((error) => {
          // TODo -add handle error
          if (!this.handleError("Error creating buy", error)) {
            throw error;
          }
        });
    },
    handlePosError(result) {
      if (result?.status !== "ok") {
        if (result?.message) {
          throw new Error(result.message);
        } else {
          throw new Error(
            "An error occurred in the RAI POS extension while checking in this buy"
          );
        }
      }
    },
    // Click handler for button
    // Massages the buy data a bit, then calls RaiExt.checkIn()
    async handleClick() {
      this.loading = true;

      if (!this.activeEmployee?.drsEmployeeCode) {
        // We need to check if the active employee has no drs code, as only after entering pin we know which user does checkin
        // If we've made it this far, show a success message
        this.showSnackbar({
          text: `Current employee does not have a drs code`,
        });

        this.loading = false;
        return;
      }

      // ---- ^Guards
      if (!this.checkinRequest) {
        this.loading = false;
        return;
      }

      if (!this.$raiPos) {
        this.loading = false;
        return this.showSnackbar({
          text: "Buys can only be checked in on a POS register running ResaleAI POS",
        });
      }

      if (
        !this.checkinRequest.signatureUrl &&
        !this.checkinRequest.signatureFileName
      ) {
        const options = this.$t("buy.app.confirmCheckinDialog");
        const confirm = await this.$dialog.confirm(options);

        if (!confirm) {
          this.loading = false;
          return;
        }
      }
      // ---- $Guards
      try {
        // # TODO - createBuyFromCheckinRequest should be idempotent
        // # also referesh the checkin request -
        const createdBuyResponse = await this.createBuyFromCheckinRequest(
          this.checkinRequest.id
        );
        const createdBuy = createdBuyResponse.data.checkinRequestCreateBuy.buy;
        const updatedCheckinRequest =
          createdBuyResponse.data.checkinRequestCreateBuy.checkinRequest;

        const drsCustomerCode =
          updatedCheckinRequest.customer.drsCustomerCode ||
          `RAI${updatedCheckinRequest.customer.id}`;

        const upsertCustomerResult =
          // DO the upsert only if something changed or if new
          await this.$raiPos.upsertCustomer(
            drsCustomerCode,
            updatedCheckinRequest.customer.firstName,
            updatedCheckinRequest.customer.lastName,
            updatedCheckinRequest.customer.phoneNumber,
            updatedCheckinRequest.customer.email,
            updatedCheckinRequest.customer.id
          );
        this.handlePosError(upsertCustomerResult);

        const createBuyResult = await this.$raiPos.createBuy(
          drsCustomerCode,
          createdBuy.id,
          this.activeEmployeeId
        );
        this.handlePosError(createBuyResult);

        // Get the buy from backend now again. To get the latest data!

        const { buy, customer } = buildCheckinPayload(createdBuy);
        const printSlipsResult = await this.$raiPos.printBuySlips(
          customer,
          buy
        );
        this.handlePosError(printSlipsResult);

        await this.$apollo.mutate({
          mutation: gql`
            mutation FINALIZE_CHECKIN_REQUEST(
              $input: FinalizeCheckinRequestInput!
            ) {
              checkinRequestFinalize(input: $input) {
                checkinRequest {
                  id
                }
                errors
              }
            }
          `,
          variables: {
            input: {
              id: this.checkinRequest.id,
              employeeId: this.activeEmployeeId,
            },
          },
        });

        // If we've made it this far, show a success message
        this.showSnackbar({
          text: `Buy checked in successfully!`,
        });

        this.hideDialog();
      } catch (error) {
        return this.showSnackbar({
          text: `An error occurred while checking in this buy. ${error}`,
        });
      }

      this.loading = false;
    },
  },
};
</script>
