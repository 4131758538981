<script>
import { usParse, caParse } from "@/utils/decode-id";
import VxDialogView from "@/components/vx/VxDialogView";

import { injectActiveEmployee } from "@/mixins/employee";

import CheckinButton from "../components/CheckinRequestCheckinButton";
import CheckinRequestToolbarTitle from "../components/CheckinRequestToolbarTitle";
import CheckinRequestCustomerForm from "../components/CheckinRequestCustomerForm";
import CheckinRequestMetaForm from "../components/CheckinRequestMetaForm";
import CheckinRequestToolbarExtension from "../components/CheckinRequestToolbarExtension";
import ShowThreadView from "@/views/Texting/AppView/ShowThreadView";
import DrsCustomerSuggestDialog from "../components/DrsCustomerSuggestDialog";

import get from "lodash/get";
import { mapGetters } from "vuex";
import { mapMutations } from "vuex";

// TODO: remove
import { hasVModel } from "@/mixins/vmodel";

import { UPSERT_CHECKIN_REQUEST, CHECKIN_REQUEST_QUERY } from "../graphql";

import { VxAlert, VxForm } from "@/core-ui";

import gql from "graphql-tag";
const verifyLicenseMutation = gql`
  mutation VERIFY_LICENSE($input: VerifyLicenseCheckinRequestInput!) {
    checkinRequestVerifyLicense(input: $input) {
      checkinRequest {
        id
        licenseVerifiedAt
      }
      errors
    }
  }
`;

const storeQuery = gql`
  query STORE_BUY_CONFIG($id: ID!) {
    store(id: $id) {
      id
      state
      buyConfig {
        id
        buysRequireEmail
        buysRequireLicense
      }
    }
  }
`;

export default {
  name: "CheckinRequestDetailView",
  components: {
    VxDialogView,
    VxAlert,
    CheckinButton,
    DrsCustomerSuggestDialog,

    CheckinRequestToolbarTitle,
    CheckinRequestCustomerForm,
    CheckinRequestMetaForm,
    CheckinRequestToolbarExtension,
    ShowThreadView,
    VxForm,
  },
  mixins: [hasVModel, injectActiveEmployee],
  props: {
    // Used only to set initial data
    checkinRequestId: {
      type: String,
      default: "",
    },
    customerRouteName: {
      type: String,
      required: true,
    },
    storeId: {
      type: [String, Number],
      default: undefined,
    },
    buyDetailsTab: {
      type: String,
      default: "buy",
    },
  },

  // TODO: code smell, fix and remove
  provide() {
    return {
      // Allow hiding the dialog from descendants
      hideDialog: () => {
        this.$router.go(-1);
      },
      // Grants descendants access to dialog v-model
      // so they can handle reset when value changes
      dialogModel: () => this.localValue,
    };
  },

  data: (vm) => ({
    // These 3 forms are acutally registered in the meta, customer and offer form components themselves.
    // Otherwise we can't be reactive to changes in them.
    customerForm: undefined,
    metaForm: undefined,

    customerLoading: false,
    metaLoading: false,

    tab: vm.buyDetailsTab,
    showEdit: false,

    checkinRequest: {
      id: vm.checkinRequestId,
      customerId: null,
      firstName: null,
      lastName: null,
      phoneNumber: null,
      email: null,
      idNumberAttached: false,
      idState: null,
      status: "pending",
      licenseVerified: null,
    },
    checkinRequestLoading: false,
    checkinRequestUpsertRunning: false,

    idVerifyErrors: undefined,
    idVerifyNumber: undefined,
    idVerifyLoading: undefined,

    idNumberAttached: undefined,
    idState: undefined,
    idDataUpdateLoading: undefined,

    store: undefined,

    drsCustomerLoading: false,

    // This should end up in general dialog service!
    suggestDialogVisible: false,
  }),
  computed: {
    ...mapGetters("sockets", ["storeChannelName"]),

    canInputFormData() {
      if (this.checkinRequest.id) {
        return true;
      } else {
        return !this.checkinRequestUpsertRunning;
      }
    },

    // TODO - cleanup checkinStatus
    checkinStatus() {
      if (!this.checkinRequest) {
        return undefined;
      }

      return this.checkinRequest.status;
    },

    checkInDisabled() {
      if (this.anyLoading) {
        return true;
      }

      if (!this.checkinRequest || !this.checkinRequest.id) {
        return true;
      }

      if (this.activeEmployee && !this.activeEmployee.drsEmployeeCode) {
        return true;
      }

      if (
        this.checkinRequest.status !== "submitted" &&
        this.checkinRequest.status !== "pending"
      ) {
        return true;
      }

      // This covers the case when the form is not yet mounted
      if (!this.$refs.checkinForm) {
        return true;
      }

      return !this.$refs.checkinForm.$refs.observer.flags.valid;
    },

    // anyLoading - returns true if any form have requests in flight
    anyLoading() {
      return [this.customerLoading, this.metaLoading].some((e) => e);
    },
    warningToShow() {
      if (this.buyCheckedInWarning) {
        return this.buyCheckedInWarning;
      }

      if (this.posWarning) {
        return this.posWarning;
      }

      if (this.drsInitialsWarning) {
        return this.drsInitialsWarning;
      }

      return null;
    },
    buyCheckedInWarning() {
      if (this.checkinRequest.status !== "waiting_drs_buy") {
        return null;
      }

      // TODO - add button for support contact
      return "Buy is created but not checked-in from DRS. Please contact support.";
    },
    posWarning() {
      if (this.$raiPos) {
        return null;
      }

      return "Buys can only be checked in on a POS register running ResaleAI POS";
    },
    drsInitialsWarning() {
      if (this.activeEmployee?.drsEmployeeCode) {
        return null;
      }

      const employeeName = this.activeEmployee?.firstName
        ? this.activeEmployee.firstName
        : this.activeEmployee?.fullName;

      if (employeeName) {
        return `Hello ${employeeName}. You cannot check-in buys because your DRS initials are missing. Please add DRS initials, or have another employee log in to finish checking in`;
      } else {
        return "This employee cannot check-in buys because their DRS initials are missing. Please add DRS initials, or have another employee log in to finish checking in";
      }
    },
    isSmallScreen() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    customerFlagged() {
      return this.checkinRequest?.drsCustomer?.customer?.flagged;
    },
    customerFlagReason() {
      return this.checkinRequest?.drsCustomer?.customer?.flagReason;
    },
  },

  apollo: {
    store: {
      query: storeQuery,
      variables() {
        return { id: this.storeId };
      },
      skip() {
        return !this.storeId;
      },
    },
    checkinRequest: {
      query: CHECKIN_REQUEST_QUERY,
      variables() {
        return {
          id: this.checkinRequest.id,
          storeId: this.storeId,
        };
      },
      skip() {
        return !this.checkinRequest.id;
      },
      update(data) {
        return data.checkinRequest;
      },
      watchLoading(isLoading) {
        this.checkinRequestLoading = isLoading;
      },
    },
  },
  watch: {
    buyDetailsTab(newTab) {
      this.tab = newTab;
    },
    store(newStore) {
      // set the ID state to the store's state only if the license is required
      if (
        newStore &&
        !this.idState &&
        get(this, "stores.buyConfig.buysRequireLicense", false)
      ) {
        // Set default state for the store, if not set already
        this.idState = this.store.state;
      }
    },
  },
  created() {
    this.mountScanListener();
  },
  destroyed() {
    this.dismountScanListener();
  },
  methods: {
    ...mapMutations("snackbar", ["showSnackbar"]),

    mountScanListener() {
      window.addEventListener("onlicenseinput", this.handleScan);
      window.addEventListener("onbarcode", this.handleBarcode);
    },

    dismountScanListener() {
      window.removeEventListener("onlicenseinput", this.handleScan);
      window.removeEventListener("onbarcode", this.handleBarcode);
    },

    // TODO: fix
    // `refs` are not reactive. Since the `checkin` button
    // relies on a descendant's computed property, we "register"
    // that descendant once it's mounted. That way, we have
    // direct access to its computed `isValid` property.
    registerForm(form, vm) {
      this[form] = vm;
    },

    // TODO: fix and remove?
    handleEdit() {
      [this.$refs.customer, this.$refs.meta, this.$refs.offer].map(
        (vm) => vm && vm.showEdit && vm.showEdit()
      );

      this.showEdit = true;
      this.changeTab("buy");
    },

    handleViewCustomer() {
      const customerRoute = {
        name: this.customerRouteName,
        params: {
          customerId: this.checkinRequest.customerId,
        },
        preserveQuery: true,
      };

      this.$router.replace(customerRoute);
    },

    changeTab(tabName) {
      this.$router.replace({
        name: this.$route.name,
        query: {
          buyDetailsTab: tabName,
        },
        preserveQuery: true,
      });
    },

    async updateCheckinRequest(
      customerData = {},
      buyData = {},
      finishedCallback
    ) {
      const setErrors = (errors) => {
        this.$refs.checkinForm.setErrors(errors);
      };
      try {
        this.checkinRequestUpsertRunning = true;

        const {
          data: {
            checkinRequestUpsert: { checkinRequest, errors },
          },
        } = await this.$apollo.mutate({
          mutation: UPSERT_CHECKIN_REQUEST,
          variables: {
            input: {
              id: this.checkinRequest.id,
              customerData: customerData,
              buyData: buyData,
            },
          },
        });
        // Handle any backend errors
        if (errors?.length > 0) {
          setErrors(errors);
          return;
        }

        // TODO - check if this still needed at all
        this.checkinRequest = checkinRequest;
      } catch (error) {
        setErrors([
          `Error occurred while updating checkin request: ${error?.message}`,
        ]);
      } finally {
        this.checkinRequestUpsertRunning = false;
        if (finishedCallback) {
          finishedCallback();
        }
      }
    },

    onFieldSubmit(context, origin, finishedCallback) {
      console.log("submit the field", context, origin);
      if (origin.includes("idVerifyLast4")) {
        this.verifyId(context.values.idVerifyLast4, finishedCallback);
      } else if (origin.includes("customer")) {
        this.updateCheckinRequest(context.values, {}, finishedCallback);
      } else if (origin.includes("buy")) {
        this.updateCheckinRequest({}, context.values, finishedCallback);
      }
    },

    // Normalizes phone prop and data, then checks
    // to see if they differ
    phoneValuesDiffer(value) {
      const prop = this.checkinRequest.phoneNumber
        ? this.checkinRequest.phoneNumber
            .replace(/^\+?1?/, "")
            .replace(/[^\d]/g, "")
        : this.checkinRequest.phoneNumber;
      const data = value?.replace(/^\+?1?/, "").replace(/[^\d]/g, "");
      return prop !== data;
    },

    // Returns `true` if the prop value and data value for the given field differ.
    hasDataChanged(field, value) {
      if (/phone/i.test(field)) {
        return this.phoneValuesDiffer(value);
      }
      return value !== this.checkinRequest[field];
    },

    async updateFormField(field, value, origin, finishedCallback) {
      if (this.hasDataChanged(field, value)) {
        const isFieldValid = await this.$refs.checkinForm.submitField(
          [field],
          origin,
          finishedCallback
        );
        if (!isFieldValid) {
          finishedCallback();
        }
      } else {
        finishedCallback();
      }
    },

    async updateIdInfo(idInfo, origin, finishedCallback) {
      const areFieldsValid = await this.$refs.checkinForm.submitField(
        Object.keys(idInfo),
        origin,
        finishedCallback
      );
      if (!areFieldsValid) {
        finishedCallback();
      }
    },

    async verifyId(idVerifyNumber, finishedCallback) {
      const setErrors = (errors) => {
        this.$refs.checkinForm.setErrors(errors);
      };
      try {
        const {
          data: {
            checkinRequestVerifyLicense: { errors },
          },
        } = await this.verifyLicense(idVerifyNumber);

        // If we've made it this far, no network error occurred. Check for GraphQL errors.
        if (errors?.length > 0) {
          setErrors(errors);
          return;
        }
      } catch (error) {
        setErrors([`Error occurred while verifying id. ${error}`]);
      } finally {
        finishedCallback();
      }
    },

    async handleScan({ detail: { parsed: v, raw } }) {
      this.updateCheckinRequest({ licenseData: v }, {}, () => {
        if (
          this.checkinRequest.licenseDrsCustomer?.id !==
          this.checkinRequest.drsCustomer?.id
        ) {
          this.suggestDialogVisible = true;
        }
      });
    },

    // Takes a scanned barcode and parses it to see if it is a license
    async handleBarcode({ detail: { data: v } }) {
      const leadingChar = v.charCodeAt(0);
      // Guard. If the barcode doesn't begin with either `@` or `%`, just return
      if ([64, 37].indexOf(leadingChar) < 0) return;

      let parsed;
      // If the first character is `@`, try to decode with usParse
      // If the first character is `%`, try to decode with caParse
      if (leadingChar === 64) {
        parsed = usParse(v);
      }
      if (leadingChar === 37) {
        parsed = caParse(v);
      }
      // If the return object has any errors, log them
      if (parsed.errors && parsed.errors.length) {
        try {
          const checksum = btoa(
            JSON.stringify({
              v,
              errors: parsed.errors,
            })
          );
          if (!!raiPos) {
            raiPos.writeLog(`checksum errors ${checksum}`);
          }
        } catch (error) {
          console.log("Error generating checksum", error);
        }
      }
      const compat = { detail: { parsed, raw: v } };
      // Pass the decoded result on to `handleScan`
      return this.handleScan(compat);
    },

    // Duplicate of VerifyLicense#handleInput
    async verifyLicense(last4) {
      // Guard
      if (!last4 || last4.length < 4) return;
      return this.$apollo.mutate({
        mutation: verifyLicenseMutation,
        variables: {
          input: {
            id: this.checkinRequest.id,
            last4: last4,
          },
        },
      });
    },

    confirmLicenseDrsCustomer() {
      this.drsCustomerLoading = true;
      this.updateCustomerField(
        "drsCustomer",
        this.checkinRequest.licenseDrsCustomer,
        () => {
          this.drsCustomerLoading = false;
        }
      );
      this.hideDrsCustomerSuggestDialog();
    },

    hideDrsCustomerSuggestDialog() {
      this.suggestDialogVisible = false;
    },
  },
};
</script>

<template>
  <VxDialogView
    :retain-focus="false"
    :v-size="'large'"
    :h-size="'medium'"
    :error-toolbar="customerFlagged"
  >
    <DrsCustomerSuggestDialog
      v-model="suggestDialogVisible"
      :drs-customer="checkinRequest.licenseDrsCustomer"
      @confirm="confirmLicenseDrsCustomer"
      @cancel="hideDrsCustomerSuggestDialog"
    />

    <template v-if="!isSmallScreen && checkinRequest" #actions>
      <CheckinButton
        color="primary"
        :checkin-request="checkinRequest"
        :disabled="checkInDisabled"
      />
    </template>

    <template v-if="isSmallScreen && checkinRequest" #large-actions>
      <CheckinButton
        color="primary"
        :checkin-request="checkinRequest"
        :disabled="checkInDisabled"
      />
    </template>

    <template #toolbar-title>
      <!-- // TODO - see if to send checkinrequest as object, or desconstructed -->
      <CheckinRequestToolbarTitle
        v-bind="{
          ...checkinRequest,
          reprintDisabled: true,
          checkinRequestLoading: checkinRequestLoading,
        }"
        @viewCustomer="handleViewCustomer"
        @reprintSlips="() => {}"
        @edit="handleEdit"
      />
    </template>

    <template #toolbar-extension>
      <CheckinRequestToolbarExtension
        :status="checkinStatus"
        :tab="tab"
        @changeTab="changeTab"
      />
    </template>

    <template>
      <v-tabs-items
        v-if="checkinRequest"
        v-model="tab"
        touchless
        class="fill-height"
      >
        <!-- BuyMeta+BuyOffer Tab -->
        <v-tab-item value="buy" class="fill-height">
          <VxAlert v-if="warningToShow" type="warning" dense>
            <template>
              {{ warningToShow }}
            </template>
          </VxAlert>
          <VxForm
            ref="checkinForm"
            :focus-on-invalid="false"
            @submitField="onFieldSubmit"
          >
            <CheckinRequestCustomerForm
              :drs-customer-loading="drsCustomerLoading"
              :can-input-form-data="canInputFormData"
              :license-verified="!!checkinRequest.licenseVerifiedAt"
              :id-verify-errors="idVerifyErrors"
              :id-verify-number="idVerifyNumber"
              :id-verify-loading="idVerifyLoading"
              :id-number-attached="checkinRequest.idNumberAttached"
              :id-state="checkinRequest.idState"
              :id-data-update-loading="idDataUpdateLoading"
              :store="store"
              :flagged="customerFlagged"
              :flag-reason="customerFlagReason"
              :phone-number="checkinRequest.phoneNumber"
              :first-name="checkinRequest.firstName"
              :last-name="checkinRequest.lastName"
              :email="checkinRequest.email"
              :drs-customer="checkinRequest.drsCustomer"
              :store-id="storeId"
              @loading="(v) => $emit('loading', v)"
              @update:field="updateFormField"
              @update:idField="updateIdInfo"
            />
            <v-divider class="my-3"></v-divider>
            <CheckinRequestMetaForm
              :can-input-form-data="canInputFormData"
              :container-num="checkinRequest.containerNum"
              :container-description="checkinRequest.containerDescription"
              :signature-file-name="checkinRequest.signatureFileName"
              :signature-url="checkinRequest.signatureUrl"
              :estimated-pickup-at="checkinRequest.estimatedPickupAt"
              :status="checkinRequest.status"
              :show-signature-field="checkinRequest.showSignatureField"
              :store-id="storeId"
              @loading="(v) => $emit('loading', v)"
              @update:field="updateFormField"
            />
          </VxForm>
        </v-tab-item>
        <!-- Messaging Tab -->
        <v-tab-item value="message" class="fill-height">
          <ShowThreadView
            v-if="checkinRequest.messageThreadId"
            :store-id="storeId"
            :thread-id="checkinRequest.messageThreadId"
            :hide-toolbar="true"
            :in-dialog="true"
          />
        </v-tab-item>
      </v-tabs-items>
    </template>
  </VxDialogView>
</template>

<style scoped lang="scss">
::v-deep .number-name-wrapper {
  overflow: hidden;

  .name-wrapper {
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

.v-tabs-items {
  overflow: visible;
}
</style>
