<template>
  <div class="container-input d-flex align-center">
    <v-btn icon text :disabled="decrementDisabled" @click="decrement">
      <v-icon v-text="`$vuetify.icons.minus`" />
    </v-btn>
    <div class="container-number-display">
      <VxTextField
        ref="containerNum"
        v-model="value"
        :outlined="false"
        name="containerNum"
        :rules="rules"
        hide-details
        readonly
        :class="[`rai-data`, `headline`, `mx-1`, `text-center`]"
      />
      <div style="height: 2px">
        <v-progress-linear
          indeterminate
          background-opacity="0"
          height="2"
          :active="loading"
        />
      </div>
    </div>
    <v-btn icon text :disabled="incrementDisabled" @click="increment">
      <v-icon v-text="`$vuetify.icons.add`" />
    </v-btn>
  </div>
</template>

<script>
import { VxTextField } from "@/core-ui";

export default {
  components: { VxTextField },
  props: {
    value: {
      type: Number,
      default: 0,
    },
    min: {
      type: Number,
      default: 0,
    },
    max: {
      type: Number,
      default: undefined,
    },
    error: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: String,
      default: "",
    },
  },
  computed: {
    localValue: {
      get() {
        return this.value || 0;
      },
      set(v) {
        this.$emit("input", v);
      },
    },
    decrementDisabled() {
      return this.disabled || (this.hasMin && this.min === this.localValue);
    },
    incrementDisabled() {
      return this.disabled || (this.hasMax && this.max === this.localValue);
    },
    hasMin() {
      return this.min !== undefined && this.min !== null;
    },
    hasMax() {
      return this.max !== undefined && this.max !== null;
    },
    computedLoading() {
      return {
        decrement: this.loading && this.lastAction === "-",
        increment: this.loading && this.lastAction === "+",
      };
    },
  },
  methods: {
    decrement() {
      if (this.decrementDisabled) return;
      this.localValue = this.value - 1;
    },
    increment() {
      if (this.incrementDisabled) return;
      this.localValue = this.value + 1;
      this.$nextTick(() => {
        this.$refs.containerNum.validator().validate();
      });
    },
  },
};
</script>

<style lang="scss">
.container-number-display {
  width: 40px;
  input {
    text-align: center;
  }
  .v-input {
    margin-top: 0px;
    padding-top: 0px;
  }
}
</style>
