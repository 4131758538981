<template>
  <DrsCustomerSelect
    :items="allCustomers"
    :search-term="query"
    :value="value"
    :hide-no-data="loading || !shouldSearch"
    no-filter
    v-bind="this.$attrs"
    v-on="this.$listeners"
    @updateSearchTerm="handleSearchUpdate"
  />
</template>

<script>
import { defineComponent } from "vue";
import { SEARCH_CUSTOMERS } from "@/graphql/drs_customer/queries.gql";
import DrsCustomerSelect from "./DrsCustomerSelect.vue";

export default defineComponent({
  name: "DrsCustomerSelectQuery",
  components: {
    DrsCustomerSelect,
  },
  props: {
    storeId: {
      type: [String, Number],
      required: true,
    },
    searchInput: {
      type: String,
      default: "",
    },
    minSearchLength: {
      type: Number,
      default: 3,
    },
    debounce: {
      type: Number,
      default: 500,
    },
    value: {
      type: Object,
      default: () => null,
    },
  },
  data(vm) {
    return {
      query: vm.searchInput?.trim() ?? "",
      loading: false,
    };
  },
  computed: {
    shouldSearch() {
      return this.query.length >= this.minSearchLength;
    },
    allCustomers() {
      let allCustomers = this.customers ?? [];
      if (!this.shouldSearch) {
        allCustomers = [];
      }

      if (this.value) {
        const hasValue = allCustomers.some(
          (drsCustomer) => drsCustomer.id === this.value.id
        );
        if (!hasValue) {
          return allCustomers.concat({
            ...this.value,
          });
        }
      }

      return allCustomers;
    },
  },
  apollo: {
    customers: {
      query: SEARCH_CUSTOMERS,
      notifyOnNetworkStatusChange: true,
      watchLoading(isLoading) {
        this.loading = isLoading;
      },
      variables() {
        return { storeId: this.storeId, input: { q: this.query } };
      },
      debounce() {
        return this.debounce;
      },
      skip() {
        return !this.storeId || !this.shouldSearch;
      },
    },
  },
  watch: {
    searchInput(v) {
      this.query = v;
    },
  },
  methods: {
    handleSearchUpdate(newQuery) {
      this.query = newQuery;
    },
  },
});
</script>
