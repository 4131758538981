<template>
  <VxDialog
    :title="$t('buy.app.suggestDrsCustomerDialog.title')"
    width="350px"
    persistent
    no-back
    v-bind="$attrs"
    v-on="$listeners"
  >
    <v-card flat>
      <v-card-text class="">
        {{ $t("buy.app.suggestDrsCustomerDialog.topText") }}
      </v-card-text>
      <v-card outlined height="auto" class="my-3">
        <DrsCustomerListItem v-bind="$props" dense />
      </v-card>
      <v-card-text>
        {{ $t("buy.app.suggestDrsCustomerDialog.bottomText") }}
      </v-card-text>
    </v-card>
    <template #actions>
      <VxBtn text class="text--disabled" @click="cancel">
        {{ $t("buy.app.suggestDrsCustomerDialog.removeButton") }}
      </VxBtn>
      <VxBtn text @click="confirm">
        {{ $t("buy.app.suggestDrsCustomerDialog.useButton") }}
      </VxBtn>
    </template>
  </VxDialog>
</template>

<script>
import { VxDialog, VxBtn } from "@/core-ui";
import DrsCustomerListItem from "./DrsCustomerListItem.vue";

export default {
  name: "DrsCustomerSuggestDialog",
  components: {
    VxDialog,
    VxBtn,
    DrsCustomerListItem,
  },
  props: {
    drsCustomer: {
      type: Object,
      default: () => ({}),
    },
  },
  methods: {
    cancel() {
      this.visible = false;
      this.$emit("cancel");
    },
    confirm() {
      this.visible = false;
      this.$emit("confirm");
    },
    show() {
      this.visible = true;
    },
  },
};
</script>
