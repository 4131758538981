<script>
import { cleanPhoneNumber } from "@/utils/phone";
import { VueMaskDirective } from "v-mask";
import { lowerFirst, get, debounce } from "lodash";
import { states } from "@/utils/lib";
import { VxTextField, VxAutocomplete, VxPhoneField } from "@/core-ui";
import DrsCustomerSelectQuery from "./DrsCustomerSelectQuery";

export default {
  name: "CheckinRequestCustomerForm",
  directives: { mask: VueMaskDirective },
  components: {
    VxPhoneField,
    VxTextField,
    VxAutocomplete,
    DrsCustomerSelectQuery,
  },
  inheritAttrs: false,
  stateItems: Object.values(states()).sort(),

  props: {
    storeId: {
      type: [String, Number],
      required: true,
    },
    store: {
      type: Object,
      default: () => undefined,
    },
    canInputFormData: {
      type: Boolean,
      default: true,
    },
    drsCustomer: {
      type: Object,
      default: null,
    },
    firstName: {
      type: String,
      default: null,
    },
    lastName: {
      type: String,
      default: null,
    },
    phoneNumber: {
      type: String,
      default: null,
    },
    email: {
      type: String,
      default: null,
    },
    driverLicense: {
      type: Object,
      default: () => ({}),
    },
    status: {
      type: String,
      default: "pending",
    },
    licenseVerified: {
      type: Boolean,
    },
    flagged: {
      type: Boolean,
      default: false,
    },
    flagReason: {
      type: String,
      default: undefined,
    },

    idVerifyNumber: {
      type: String,
      default: "",
    },
    idVerifyLoading: {
      type: Boolean,
    },
    idVerifyErrors: {
      type: Array,
      default: () => [],
    },

    idNumberAttached: {
      type: Boolean,
      default: false,
    },
    idState: {
      type: String,
      default: "",
    },
    idDataUpdateLoading: {
      type: Boolean,
    },

    drsCustomerLoading: {
      type: Boolean,
      default: false,
    },
  },
  data: (vm) => {
    return {
      localFirstName: vm.firstName,
      localLastName: vm.lastName,
      localPhoneNumber: vm.phoneNumber
        ? vm.phoneNumber.replace(/^\+1/, "")
        : vm.phoneNumber,
      localEmail: vm.email,
      localIdNumber: undefined,
      localIdState: states()[vm.idState],
      localIdVerifyLast4: undefined,
      internalDrsCustomer: vm.drsCustomer,
      loading: [],
      menu: false,
      overwriteLicense: false,
      renderKey: 0,
      searchTerm: "",
    };
  },
  computed: {
    computedLoading() {
      return {
        localFirstName: this.loading.includes("localFirstName"),
        localLastName: this.loading.includes("localLastName"),
        localPhoneNumber: this.loading.includes("localPhoneNumber"),
        localEmail: this.loading.includes("localEmail"),
        localIdNumber: this.loading.includes("localIdNumber"),
        localIdState: this.loading.includes("localIdState"),
        localIdVerifyLast4: this.loading.includes("localIdVerifyLast4"),
        localDrsCustomer:
          this.drsCustomerLoading || this.loading.includes("localDrsCustomer"),
      };
    },
    // Returns true if the store requires licenses for buys -
    // AND there is no license on file.
    shouldShowIdNumberField() {
      return (
        !this.licenseVerified &&
        (!this.idNumberAttached || !this.idState || this.overwriteLicense)
      );
    },
    // If the customer has a license that has not yet been verified for this buy,
    // show the last4 verification component
    shouldShowVerifyLast4() {
      return (
        this.idNumberAttached &&
        this.idState &&
        !this.licenseVerified &&
        !this.overwriteLicense
      );
    },
    shouldShowCancelOverwrite() {
      return this.overwriteLicense;
    },
    // If the customer has a license that has been verified for this buy,
    // show that there is one attached
    shouldShowIdAttached() {
      return (
        this.idNumberAttached &&
        this.idState &&
        this.licenseVerified &&
        !this.overwriteLicense
      );
    },
    localDrsCustomer: {
      get() {
        if (this.internalDrsCustomer && this.internalDrsCustomer.id == null) {
          return {
            id: null,
            fullName: this.$t("buy.app.newDrsCustomer.name", {
              firstName: this.localFirstName,
              lastName: this.localLastName,
            }),
            customer: {
              phoneNumber: this.localPhoneNumber,
              numBuys: 0,
            },
          };
        } else {
          return this.internalDrsCustomer;
        }
      },
      set(v) {
        this.internalDrsCustomer = v;
      },
    },
    compareDrsCustomer() {
      return {
        phoneNumber: this.localPhoneNumber,
        firstName: this.localFirstName,
        lastName: this.localLastName,
        fullName:
          this.localFirstName && this.localLastName
            ? this.localFirstName + " " + this.localLastName
            : null,
      };
    },
    emailRequired() {
      return get(this, "store.buyConfig.buysRequireEmail") ? "required" : "";
    },
    verifyLast4Required() {
      return get(this, "store.buyConfig.buysRequireLicense", false) &&
        get(this, "driverLicense.id") &&
        !get(this, "licenseVerified")
        ? "required"
        : "";
    },
    idNumberRequired() {
      return get(this, "store.buyConfig.buysRequireLicense", false) &&
        !get(this, "driverLicense.id")
        ? "required"
        : "";
    },
    idStateRequired() {
      return get(this, "store.buyConfig.buysRequireLicense", false) &&
        !get(this, "driverLicense.id")
        ? "required"
        : "";
    },
  },
  watch: {
    // Emit loading events
    loading(v) {
      this.$emit("loading", !!v.length);
    },
    idState(newValue) {
      if (newValue) {
        this.localIdState = states()[newValue];
      }
    },
    idDataUpdateLoading(newValue) {
      if (newValue) {
        if (!this.computedLoading["localIdNumber"])
          this.loading.push("localIdNumber");
        if (!this.computedLoading["localIdState"])
          this.loading.push("localIdState");
      } else {
        this.loading.splice(this.loading.indexOf("localIdNumber"), 1);
        this.loading.splice(this.loading.indexOf("localIdState"), 1);
      }
    },
    // If the cache is updated, make sure we update the text field model
    firstName(v) {
      if (v) {
        this.localFirstName = v;
      }
    },
    // If the cache is updated, make sure we update the text field model
    lastName(v) {
      if (v) {
        this.localLastName = v;
      }
    },
    // If the cache is updated, make sure we update the text field model
    email(v) {
      if (v) {
        this.localEmail = v;
      }
    },
    // If the cache is updated update phone number
    phoneNumber(v) {
      this.localPhoneNumber = v ? v.replace(/^\+1/, "") : v;
    },
    drsCustomer(v) {
      this.localDrsCustomer = v;
    },
  },
  methods: {
    // Turns `localFirstName` into `firstName`
    transformFieldToInputKey(field) {
      return lowerFirst(field.replace(/^local/, ""));
    },
    // Update the customer on blur, if the field is valid
    handleBlur(field) {
      // Toggle loading on the field
      if (!this.computedLoading[field]) {
        this.loading.push(field);
      }

      const originalFieldName = this.transformFieldToInputKey(field);

      this.$emit(
        "update:field",
        originalFieldName,
        this[field],
        `customer-${originalFieldName}`,
        () => {
          // Stop loading
          this.loading.splice(this.loading.indexOf(field), 1);
        }
      );
    },
    // This is in a separate mutation because it's not a simple
    // update. We have to create a
    handleIdBlur(field) {
      // Guard. Don't save anything if any of the elements are falsy
      // localIdNumber, localIdState
      if ([!this.localIdNumber, !this.localIdState].some((e) => e))
        return false;

      if (!this.computedLoading[field]) this.loading.push(field);

      this.$emit(
        "update:idField",
        {
          idNumber: this.localIdNumber,
          idState: this.localIdState,
        },
        `customer-${field}`,
        () => {
          // Stop loading
          this.loading.splice(this.loading.indexOf(field), 1);
        }
      );
    },
    handleBlurDebounced: debounce(function (field) {
      this.handleBlur(field);
    }, 300),

    enableOverwriteLicense() {
      this.overwriteLicense = true;
    },
    cancelOverwriteLicense() {
      this.overwriteLicense = false;
    },
    onCreateDrsCustomer(v) {
      this.localDrsCustomer = {
        id: null,
      };
      this.handleBlur("localDrsCustomer");
    },
    async onSelectDrsCustomer(v) {
      if (this.drsCustomerDataConflict(v)) {
        const options = {
          title: this.$t("buy.app.conflictWarning.title"),
          message: this.$t("buy.app.conflictWarning.text"),
          cancelText: this.$t("buy.app.conflictWarning.createButton"),
          okText: this.$t("buy.app.conflictWarning.leaveButton"),
        };
        const confirm = await this.$dialog.confirm(options);
        if (confirm) {
          this.localDrsCustomer = v;
          this.handleBlur("localDrsCustomer");
        } else {
          this.onCreateDrsCustomer();
        }
      } else {
        this.localDrsCustomer = v;
        this.handleBlur("localDrsCustomer");
      }
    },
    drsCustomerDataConflict(drsCustomer) {
      if (!drsCustomer || !drsCustomer.id) {
        return false;
      }

      const localDrsCustomerName = (drsCustomer?.fullName || "").toUpperCase();

      const compareDrsCustomerName = (
        this.compareDrsCustomer?.fullName || ""
      ).toUpperCase();

      const localPhoneNumber = cleanPhoneNumber(
        drsCustomer?.customer?.phoneNumber
      );
      const comparePhoneNumber = cleanPhoneNumber(
        this.compareDrsCustomer?.phoneNumber
      );

      if (!compareDrsCustomerName || !comparePhoneNumber) {
        // Means nothing was inputed for name or phone
        return false;
      }

      return (
        localDrsCustomerName !== compareDrsCustomerName ||
        localPhoneNumber !== comparePhoneNumber
      );
    },
    onClearDrsCustomer() {
      this.localDrsCustomer = null;
    },
  },
};
</script>

<template>
  <div class="buy-dialog-customer-form">
    <span class="d-flex align-center text-subtitle-2">
      <v-icon class="mr-2">$user</v-icon>
      Customer info
    </span>

    <v-row class="mt-2 mx-5">
      <v-col cols="12" md="6">
        <v-row>
          <v-col class="py-0">
            <VxPhoneField
              ref="localPhoneNumber"
              v-model="localPhoneNumber"
              :disabled="!canInputFormData"
              dense
              label="Phone number"
              name="phoneNumber"
              mode="eager"
              :loading="computedLoading.localPhoneNumber"
              autocomplete="off"
              rules="required|na_phone"
              @input="handleBlurDebounced('localPhoneNumber', $event)"
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col class="py-0">
            <!-- first name -->
            <VxTextField
              ref="localFirstName"
              v-model="localFirstName"
              dense
              label="First name"
              name="firstName"
              mode="eager"
              :loading="computedLoading.localFirstName"
              autocomplete="off"
              :disabled="!canInputFormData"
              rules="required|min:2"
              @blur="handleBlur('localFirstName', $event)"
            />
          </v-col>
          <v-col class="py-0">
            <!-- last name -->
            <VxTextField
              ref="localLastName"
              v-model="localLastName"
              dense
              label="Last name"
              name="lastName"
              mode="eager"
              :loading="computedLoading.localLastName"
              autocomplete="off"
              :disabled="!canInputFormData"
              rules="required|min:2"
              @blur="handleBlur('localLastName', $event)"
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col class="py-0">
            <VxTextField
              ref="localEmail"
              v-model="localEmail"
              dense
              label="Email"
              name="email"
              mode="eager"
              :loading="computedLoading.localEmail"
              type="email"
              autocomplete="off"
              :disabled="!canInputFormData"
              :rules="`email|${emailRequired}`"
              @blur="handleBlur('localEmail', $event)"
            />
          </v-col>
        </v-row>
      </v-col>

      <v-col cols="12" md="6">
        <v-row>
          <v-col class="py-0">
            <DrsCustomerSelectQuery
              :store-id="storeId"
              :loading="computedLoading.localDrsCustomer"
              :value="localDrsCustomer"
              :compare-drs-customer="compareDrsCustomer"
              :search-input="searchTerm"
              name="drsCustomer"
              rules="required"
              @create="onCreateDrsCustomer"
              @click:clear="onClearDrsCustomer"
              @select="onSelectDrsCustomer"
            />
          </v-col>
        </v-row>

        <v-row v-if="shouldShowVerifyLast4">
          <v-col class="py-0">
            <VxTextField
              ref="localIdVerifyLast4"
              v-model="localIdVerifyLast4"
              :disabled="!canInputFormData"
              dense
              label="Last 4 digits of ID"
              name="idVerifyLast4"
              mode="eager"
              :loading="computedLoading.localIdVerifyLast4"
              autocomplete="off"
              :hint="`Enter the last 4 digits of the customer's ID number`"
              :rules="`length:4|${verifyLast4Required}`"
              @input="handleBlurDebounced('localIdVerifyLast4', $event)"
            />
            <!-- Overwrite License -->
            <v-btn
              text
              color="secondary"
              @click="enableOverwriteLicense"
              v-text="`Add new license`"
            />
          </v-col>
        </v-row>

        <v-row v-if="!shouldShowVerifyLast4 && shouldShowIdNumberField">
          <v-col class="py-0">
            <VxTextField
              ref="localIdNumber"
              key="id-number"
              v-model="localIdNumber"
              :disabled="!canInputFormData"
              dense
              label="ID Number"
              name="idNumber"
              mode="eager"
              :loading="computedLoading.localIdNumber"
              autocomplete="no"
              :rules="idNumberRequired"
              @blur="handleIdBlur('localIdNumber')"
            />
          </v-col>
        </v-row>

        <v-row v-if="!shouldShowVerifyLast4 && shouldShowIdNumberField">
          <v-col class="py-0">
            <VxAutocomplete
              ref="localIdState"
              key="id-state"
              v-model="localIdState"
              :disabled="!canInputFormData"
              dense
              label="ID State"
              name="idState"
              :items="$options.stateItems"
              :loading="computedLoading.localIdState"
              autocomplete="no"
              :rules="idStateRequired"
              @blur="handleIdBlur('localIdState')"
            />
          </v-col>
        </v-row>

        <!-- Cancel overwrite license -->
        <v-row v-if="shouldShowCancelOverwrite">
          <v-col>
            <v-btn text @click="cancelOverwriteLicense" v-text="`Cancel`" />
          </v-col>
        </v-row>

        <v-row v-if="shouldShowIdAttached">
          <v-col>
            <h5 class="text-h6 mr-6 mt-4">License attached</h5>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<style lang="scss">
.customer-field {
  flex-basis: 50%;
}

.buy-dialog-customer-form .v-text-field.v-input--dense {
  padding-top: 12px;
}
</style>
