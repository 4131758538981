import { get, groupBy, reduce } from "lodash";

import { compareAsc, format, parseISO } from "date-fns";
import { stripNulls, snakify } from "./lib";

// =====================
// > Activity Feed Utils
// =====================
// Returns a boolean of whether the buy has any activity
// @param {Object} buy - The buy to check for activity
// @returns {boolean} The buy has activity?
export const hasActivity = (buy) => {
  return [
    get(buy, "checkedInAt"),
    get(buy, "startedAt"),
    get(buy, "completedAt"),
  ].some((e) => e);
};

const actionMap = {
  created: "Created by",
  checkedin: "Checked in by",
  sorted: "Sorting started by",
  completed: "Completed by",
  closedat: "Closed by",
};

export const activityString = ({ action, name }) => {
  if (!action) return "";

  action = action.toLowerCase();

  return `${actionMap[action]} ${name || "<not specified>"}`;
};

export const employeeFromInitials = (employees, initials) => {
  if (!initials) return null;

  return employees.find(
    ({ drsEmployeeCode }) =>
      drsEmployeeCode.toLowerCase() === initials.toLowerCase()
  );
};

// Returns an array of data points in the shape of
// { action: 'created|checkedIn|etc', time: 'timestamp', name: 'employeeFullName' }
export const timelineDataPoints = (buy, employees) => {
  const createdBy =
    get(employeeFromInitials(employees, get(buy, "createdBy")), "fullName") ||
    get(buy, "drsEmployeeCode");

  const closedBy =
    get(buy, "buy.closedBy") ||
    get(employeeFromInitials(employees, get(buy, "closedBy")), "fullName") ||
    get(buy, "drsEmployeeCode");

  return [
    {
      action: "created",
      time: get(buy, "createdAt") || get(buy, "buy.createdAt"),
      name: createdBy,
    },
    {
      action: "checkedIn",
      time: get(buy, "buy.checkedInAt"),
      name: get(buy, "buy.checkedInBy.fullName"),
    },
    {
      action: "sorted",
      time: get(buy, "buy.startedAt"),
      name: get(buy, "buy.startedBy.fullName"),
    },
    {
      action: "completed",
      time: get(buy, "buy.completedAt"),
      // TODO - Implement completedBy
      name: get(buy, "buy.completedBy.fullName"),
    },
    {
      action: "closedAt",
      time: get(buy, "buy.closedAt") || get(buy, "closeDate") || null,
      name: closedBy,
    },
  ];
};

// Returns an object where the keys are dates and
// the values are the events that occurred on that date.
export const timelineFeed = (buy, employees) => {
  const dataPoints = timelineDataPoints(buy, employees);

  const sorted = [...dataPoints].sort(({ time: a }, { time: b }) => {
    return compareAsc(parseISO(a), parseISO(b));
  });
  const grouped = groupBy(sorted, ({ time }) => {
    return (time && format(parseISO(time), "yyyy-MM-dd")) || null;
  });
  return pickDates(grouped);
};

// Prettifies groupedTimelineDates by only returning
// keys which are valid dates
export const pickDates = (groupedDates) => {
  return (
    reduce(
      Object.keys(groupedDates),
      (feed, key) => {
        if (key && key !== "Invalid Date") {
          feed[key] = groupedDates[key];
        }
        return feed;
      },
      {}
    ) || null
  );
};

// Returns an object with the keys `buy`, `customer`, and `loyalty`
// The object has snake_case keys, which the RaiExt expects
export const buildCheckinPayload = (buy) => {
  // make a clone of the buy
  let buyData = { ...buy };
  // make a clone of the customer
  let customerData = { ...buyData.customer };
  // delete the customer property from the buy
  delete buyData.customer;
  // clean up and serialize the objects
  const [buyJson, customerJson] = [buyData, customerData]
    .map((obj) => stripNulls(obj))
    .map((obj) => snakify(obj))
    .map((obj) => JSON.stringify(obj));

  return {
    buy: buyJson,
    customer: customerJson,
    loyalty: customerData.loyalty,
  };
};
